import { VerticalCard } from '../VerticalCard';
import SimpleIconsBoosty from '~icons/simple-icons/boosty';
import RiTwitchFill from '~icons/ri/twitch-fill';
import ClarityCoinBagSolid from '~icons/clarity/coin-bag-solid';
import OcticonSparkleFill16 from '~icons/octicon/sparkle-fill-16';
import { Accordion } from '../Accordion';
import { Heading } from '../Heading';
import { TDiscordInviteCard } from '@/utils/types/TDiscordInviteCard';

export default function DiscordPreview() {
  const discordInviteData: TDiscordInviteCard[] = [
    {
      title: 'twitch',
      description: 'подписка любого уровня',
      bg: 'bg-conic-purple',
      link: 'https://www.twitch.tv/tijoe',
      price: 'от\n130₽',
      isSmall: true,
      icon: {
        component: <RiTwitchFill />,
        color: 'text-purple-800/25',
      },
    },
    {
      title: 'boosty',
      description: 'подписка любого уровня',
      link: 'https://boosty.to/tijoe/purchase/1399898',
      bg: 'bg-conic-amber',
      price: 'от\n130₽',
      isSmall: false,
      icon: {
        component: <SimpleIconsBoosty />,
        color: 'text-amber-700/25',
      },
    },
    {
      title: 'twitch',
      description: 'награда за баллы канала',
      link: 'https://www.twitch.tv/tijoe',
      bg: 'bg-conic-purple',
      price: 'за\n12 500',
      isSmall: true,
      icon: {
        component: <ClarityCoinBagSolid />,
        color: 'text-purple-800/25',
      },
    },
  ];

  return (
    <article className='relative flex flex-col justify-center items-center gap-10 md:gap-28 overflow-hidden'>
      <section className='flex flex-col justify-center items-center gap-2 md:gap-3 z-10'>
        <p className='text-sm md:text-xl'>Хотите быть ближе к комьюнити?</p>
        <Heading
          className='text-2xl md:text-5xl leading-tight text-center bg-gradient-to-r from-[#EB9BFF] to-[#C153FF]
                        bg-clip-text text-transparent md:py-3'
          items='получите инвайт на дискорд сервер логово шакалов'
        />
      </section>

      <div className='relative flex flex-col md:flex-row items-center gap-3 md:gap-5 w-full md:w-fit'>
        {discordInviteData.map((item, index) => (
          <VerticalCard
            key={index}
            title={item.title}
            description={item.description}
            link={item.link}
            bg={item.bg}
            price={item.price}
            isSmall={item.isSmall}
            icon={item.icon.component}
            iconColor={item.icon.color}
          />
        ))}

        <OcticonSparkleFill16 className='hidden md:absolute center-0 -left-16 text-4xl text-[#E98FFF] -rotate-12' />
        <OcticonSparkleFill16 className='hidden md:absolute -top-16 -right-32 text-9xl text-[#E98FFF] rotate-12' />
      </div>

      <section className='flex flex-col gap-3 w-full'>
        <Accordion
          title='Я подписался, что дальше?'
          description='В зависимости от способа: при платной подписке на Twitch перейдите в настройки
                        профиля → интеграции → выберите интеграцию с Twitch, при подписке на бусти создайте интеграцию
                        с Discord на сайте Boosty в профиле, при получении награды за баллы модератор канала на Twitch
                        выдаст Вам ссылку-инвайт в личные сообщения, не забудьте открыть их.'
        />
        <Accordion
          title='На какой промежуток времени предоставляется доступ к серверу?'
          description='Доступ к серверу предоставляется на неограниченное количество времени пока Вас не
                        забанят или Вы не покинете сервер самостоятельно.'
        />
        <Accordion
          title='Есть ли какая-то существенная разница между способом получения инвайта?'
          description='Все способы получения приглашения на сервер являются полностью одинаковыми по
                        предоставленным возможностям на Discord сервере. Выбирайте любой, который подходит для
                        Вас больше всего.'
        />
      </section>

      <div
        className={
          '-z-10 hidden md:flex absolute bg-gradient-radial from-[#FFD074] to-50% w-[90rem] h-[70rem] opacity-10 -top-10 left-10'
        }
      />
      <div
        className={
          '-z-10 hidden md:flex absolute bg-gradient-radial from-[#E865FF] to-50% w-[90rem] h-[70rem] opacity-10 -top-10 right-10'
        }
      />
    </article>
  );
}
