import Image from "next/image";
import RiArrowRightLine from '~icons/ri/arrow-right-line'
import { Heading } from "../Heading";
import Link from 'next/link';

export default function GameReviewsPreview() {
    return (
        <article className="relative flex gap-8 md:gap-0 flex-col md:flex-row items-center justify-center">
          <Image
              src='games.png'
              width={600}
              height={600}
              draggable={true}
              priority={true}
              alt='Превью игровых рецензий'
              className="max-w-xl w-full h-auto"
          />

          <section className="flex flex-col gap-2 text-center items-center">
            <p className="text-sm md:text-xl">Интересно мнение о вашей любимой игре?</p>
            <Heading
                className="text-2xl md:text-5xl leading-7 bg-gradient-to-r from-[#FF8FBA] to-[#FF517F] bg-clip-text
                  text-transparent md:py-3"
                items='игровые рецензии'/>
            <p className="text-sm md:text-xl">Обзоры на игры, которые Тиджой прошёл на своих трансляциях вместе с записями
              стримов или роликами с основного канала на ютубе.</p>
            <Link
              href="/game-reviews"
              className="flex flex-row items-center justify-center text-xs md:text-base gap-3 hover:bg-[#282828]/50
              bg-[#151515]/50 rounded-2xl px-5 p-2 md:px-8 border hover:border-white/30 border-white/10 w-fit mt-3 md:mt-5 transition-all duration-200 ease-in-out">
                Смотреть <RiArrowRightLine/>
            </Link>
          </section>
        </article>
    )
}