import { akony } from "@/utils/fonts/fonts";
import React, { FC } from "react"
import classNames from 'classnames';

interface Props {
    className?: string,
    items: React.ReactNode
}

export const Heading: FC<Props> = (props) => {
    return <h1 style={akony.style} className={classNames('leading-snug', props.className)}>{props.items}</h1>;
}