import Image from "next/image";
import RiArrowRightLine from '~icons/ri/arrow-right-line'
import { Heading } from "../Heading";
import Link from 'next/link';

export default function MusicPreview() {
    return (
        <article className="flex flex-col gap-8 md:gap-0 md:flex-row items-center justify-center">
          <section className="flex flex-col gap-2 text-center items-center order-2 md:order-none">
              <p className="text-sm md:text-xl">Понравился трек со стрима?</p>
              <Heading
                  className="text-2xl md:text-5xl leading-7 bg-gradient-to-r from-[#C5FF97] to-[#7CC741] bg-clip-text
                    text-transparent md:py-3"
                  items='музыка со стримов'/>
              <p className="text-sm md:text-xl">Список последних треков, которые играли недавно на стриме.</p>
              <Link
                href="/streamed-music"
                className="flex flex-row items-center justify-center text-xs md:text-base gap-3 hover:bg-[#282828]/50
                bg-[#151515]/50 rounded-2xl px-5 p-2 md:px-8 border hover:border-white/30 border-white/10 w-fit mt-3 md:mt-5 transition-all duration-200 ease-in-out">
                  Смотреть
                  <RiArrowRightLine/>
              </Link>
          </section>

          <Image
              src='music.png'
              width={700}
              height={500}
              draggable={true}
              priority={true}
              alt='Превью музыки со стримов'
              className="md:max-w-xl md:w-auto h-auto"
          />
        </article>
    )
}