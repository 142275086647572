'use client'

import React, { FC, ReactNode } from "react"
import RiArrowRightUpLine from '~icons/ri/arrow-right-up-line'
import { Heading } from "./Heading"
import classNames from 'classnames';

interface Props {
    icon: ReactNode,
    iconColor: string,
    title: string,
    description: string,
    link: string,
    bg: string,
    price: string,
    isSmall: boolean
}

export const VerticalCard: FC<Props> = (props) => {
    const openLink = (link: string) => {
        window.open(link, '_blank');
    }

    return (
        <button onClick={() => openLink(props.link)} className="rounded-3xl w-full">
            <div className={classNames({'w-full h-32 md:w-60 md:h-80': props.isSmall, 'w-full h-32 md:w-72 md:h-96': !props.isSmall}, props.bg, 'group relative text-black rounded-3xl p-3 md:p-5 overflow-hidden bg-cover flex flex-col justify-between items-center cursor-pointer hover:brightness-125 hover:scale-105 duration-700 ease-out text-left')}>
                <section className="flex flex-row justify-between items-center w-full z-20">
                    <p className="text-xl md:text-3xl font-black tracking-wide whitespace-pre-line leading-none">{props.price}</p>
                    <div className="text-3xl"><RiArrowRightUpLine/></div>
                </section>
                <section className="flex flex-col gap-1 w-full z-20">
                    <p className="text-sm whitespace-pre-line font-medium leading-3">{props.description}</p>
                    <Heading className="text-xl md:text-2xl leading-5" items={props.title}/>
                </section>
                <div className={`${props.iconColor} absolute text-[14rem] md:text-[20rem] -right-10 -bottom-14 md:-bottom-32 z-10`}>{props.icon}</div>
            </div>
        </button>
    )
}