import Head from "next/head";
import { FC } from "react";

interface Props {
    canonicalUrl: string;
    image?:
      | {
          alt: string;
          dimensions: {
            height: number;
            width: number;
          };
          url: string;
        }
      | undefined;
    pageDescription: string;
    pageTitle: string;
    robots: string;
  }
  
export const HeadMetadata: FC<Props> = (props) => {
    return (
        <Head>
             <meta
                key="meta-viewport"
                name="viewport"
                content="width=device-width,minimum-scale=1,initial-scale=1,viewport-fit=cover"
            />

            <meta key="meta-color-scheme" name="color-scheme" content="dark" />

            <meta key="meta-robots" name="robots" content={props.robots} />
            <link key="link-sitemap" rel="sitemap" href={`${process.env['SITE_URL'] || ''}/sitemap.xml`} />

            <title key="title">{props.pageTitle}</title>
            <meta key="meta-description" name="description" content={props.pageDescription} />
            <link key="link-canonical" rel="canonical" href={props.canonicalUrl} />

            <meta key="meta-twitter-card" name="twitter:card" content="summary" />

            <meta key="meta-og-type" property="og:site_name" content="Тиджой" />
            <meta key="meta-og-type" property="og:type" content="website" />
            <meta key="meta-og-locale" property="og:locale" content="ru" />
            <meta key="meta-og-title" property="og:title" content={props.pageTitle} />
            <meta key="meta-og-description" property="og:description" content={props.pageDescription} />
            <meta key="meta-og-url" property="og:url" content={props.canonicalUrl} />

            {props.image === undefined ? undefined : (
                <>
                    <meta key="meta-og-image" property="og:image" content={props.image.url} />
                    <meta key="meta-og-image-width" property="og:image:width" content={props.image.dimensions.width.toString()} />
                    <meta
                        key="meta-og-image-height"
                        property="og:image:height"
                        content={props.image.dimensions.height.toString()}
                    />
                    <meta key="meta-og-image-alt" property="og:image:alt" content={props.image.alt} />
                </>
            )}
        </Head>
    )
}