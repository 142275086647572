"use client"

import Link from "next/link"
import classNames from 'classnames'
import React, { FC } from "react"
import { Heading } from "./Heading"

interface Props {
    icon: React.ReactNode,
    iconColor: string,
    title: string,
    description: string,
    link: string,
    bg: string,
    isLink: boolean,
    className?: string
}

export const LinkCard: FC<Props> = (props) => {
    const moveTo = (link: string) => {
        const scrollTopOffset = document.getElementById(link)!.getBoundingClientRect().top + document.body.scrollTop - 175;
    
        window.scrollTo({
            top: scrollTopOffset,
            left: 0,
            behavior: "smooth",
        });
    }

    if (props.isLink) {
        return (
            <Link 
                href={props.isLink ? props.link : ''} 
                target={props.isLink ? '_blank' : ''} 
                className={classNames(props.className)}>
                <section className={classNames('hover:brightness-150 transition-all duration-300 ease-out relative overflow-hidden flex flex-col justify-end p-2 md:p-3 px-4 md:px-6 rounded-3xl h-28 md:h-40 text-black bg-cover md:gap-2', props.bg)}>
                    <p className="md:flex hidden text-sm whitespace-pre-line font-medium group-hover:tracking-wide duration-500 ease-out z-10 leading-4">{props.description}</p>
                    <Heading className="md:flex hidden text-xl md:text-3xl group-hover:tracking-[0.5rem] duration-500 ease-out z-10" items={props.title}/>
                    <div className={classNames('justify-center flex md:absolute md:-bottom-32 md:-right-28 text-[5rem] md:text-[20rem]', props.iconColor)}>{props.icon}</div>
                </section>
            </Link>
        )
    } else {
        return (
            <button
                onClick={() => moveTo(props.link)}
                className={classNames(props.className)}>
                <section className={classNames('hover:brightness-150 transition-all text-left duration-300 ease-out relative overflow-hidden flex flex-col justify-end p-2 md:p-3 px-4 md:px-6 rounded-3xl h-28 md:h-40 text-black bg-cover md:gap-2', props.bg)}>
                    <p className="md:flex hidden text-sm whitespace-pre-line font-medium group-hover:tracking-wide duration-500 ease-out z-10 leading-4">{props.description}</p>
                    <Heading className="md:flex hidden text-xl md:text-3xl group-hover:tracking-[0.5rem] duration-500 ease-out z-10" items={props.title}/>
                    <div className={classNames('justify-center flex md:absolute md:-bottom-32 md:-right-28 text-[5rem] md:text-[20rem]', props.iconColor)}>{props.icon}</div>
                </section>   
            </button>
        )
    }
}