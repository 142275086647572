"use client"

import { FC, useState } from "react"
import classNames from "classnames";
import RiAddLine from '~icons/ri/add-line'
import { ibm } from "@/utils/fonts/fonts";

interface Props {
    title: string,
    description: string,
}

export const Accordion: FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <button 
            onClick={() => setIsOpen(!isOpen)}
            className="flex flex-col gap-1 select-none text-left w-full hover:bg-[#282828]/50 bg-[#151515]/50 rounded-2xl p-3 px-5 md:p-5 md:px-8 border hover:border-white/30 border-white/10 transition-all duration-200 ease-in-out">
            <div className="flex flex-row justify-between items-center w-full">
                <p className="font-medium text-xs md:text-base w-4/5 leading-4">{props.title}</p>
                <RiAddLine className={
                  classNames('text-2xl md:text-3xl transition-all duration-300 border border-zinc-500 rounded-full p-1.5 text-zinc-500',
                  {'rotate-45': isOpen, 'rotate-0': !isOpen})}/>
            </div>
            <p style={ibm.style} className={classNames('text-xs md:text-base text-zinc-300 pr-5', {'visible': isOpen, 'hidden': !isOpen})}>{props.description}</p>
        </button>
    )
}