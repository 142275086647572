import { TLinkCard } from '@/utils/types/TLinkCard';
import { GetStaticProps, NextPage } from "next";
import { LinkCard } from "@/components/LinkCard";
import DiscordPreview from "@/components/preview/DiscordPreview";
import GameReviewsPreview from "@/components/preview/GameReviewsPreview";
import MusicPreview from "@/components/preview/MusicPreview";
import RiTelegramFill from '~icons/ri/telegram-fill.jsx'
import RiYoutubeFill from '~icons/ri/youtube-fill.jsx'
import RiTwitchFill from '~icons/ri/twitch-fill.jsx'
import RiArrowDownSLine from '~icons/ri/arrow-down-s-line.jsx'
import EntypoSocialLastfm from '~icons/entypo-social/lastfm.jsx'
import IonLogoVk from '~icons/ion/logo-vk.jsx'
import IcBaselineDiscord from '~icons/ic/baseline-discord.jsx'
import SimpleIconsBoosty from '~icons/simple-icons/boosty'
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import TijoeMiniModel from '@/components/TijoeMiniModel';
import { HeadMetadata } from '@/components/HeadMetadata';
import Image from 'next/image';
import { NextPageWithLayout } from './_app';
import RootLayout from './RootLayout';

interface Props {
    siteUrl: string;
}

const Home: NextPageWithLayout<Props> = (props) =>  {
    const cardData: TLinkCard[] = [
        {
            title: 'discord',
            description: 'тесное комьюнити',
            bg: 'bg-conic-purple',
            icon: {
                component: <IcBaselineDiscord/>,
                color: 'text-purple-800/50 md:text-purple-800/25',
            },
            link: {
                path: 'discord',
                isLink: false,
            }
        }, {
            title: 'boosty',
            description: 'эксклюзивный контент\nранний доступ\nзаписи просмотровых стримов',
            bg: 'bg-conic-amber',
            icon: {
                component: <SimpleIconsBoosty/>,
                color: 'text-amber-700/50 md:text-amber-700/25',
            },
            link: {
                path: 'https://boosty.to/tijoe',
                isLink: true,
            }
        }, {
            title: 'вконтакте',
            description: 'самые важные новости',
            bg: 'bg-conic-blue',
            icon: {
                component: <IonLogoVk/>,
                color: 'text-blue-800/50 md:text-blue-800/25',
            },
            link: {
                path: 'https://vk.com/tijoevideos',
                isLink: true,
            }
        }, {
            title: 'телеграм',
            description: 'канал-блог\nуведомления о стримах',
            bg: 'bg-conic-blue',
            icon: {
                component: <RiTelegramFill/>,
                color: 'text-blue-800/50 md:text-blue-800/25',
            },
            link: {
                path: 'https://t.me/tijoe',
                isLink: true,
            }
        }, {
            title: 'lastfm',
            description: 'вся моя музыка транслируется туда',
            bg: 'bg-conic-rose',
            icon: {
                component: <EntypoSocialLastfm/>,
                color: 'text-rose-800/50 md:text-rose-800/25',
            },
            link: {
                path: 'https://www.last.fm/ru/user/TijoeMusic',
                isLink: true,
            }
        }, {
            title: 'youtube',
            description: 'большой канал с крутыми видосами\nканал поменьше с нарезками стримов\nсовсем маленький мини Тидж',
            bg: 'bg-conic-rose',
            icon: {
                component: <RiYoutubeFill/>,
                color: 'text-rose-800/50 md:text-rose-800/25',
            },
            link: {
                path: 'https://www.youtube.com/@TijoeVideos',
                isLink: true,
            }
        }, {
            title: 'twitch',
            description: 'все трансляции проводятся здесь',
            bg: 'bg-conic-purple',
            icon: {
                component: <RiTwitchFill/>,
                color: 'text-purple-800/50 md:text-purple-800/25',
            },
            link: {
                path: 'https://www.twitch.tv/tijoe',
                isLink: true,
            },
        },
    ]
    
    return (
      <>
        <HeadMetadata 
            canonicalUrl={props.siteUrl}
            pageTitle='Главная › Тиджой'
            pageDescription='Официальный сайт Тиджоя.'
            robots='index,follow'
        />

        <article className="h-auto flex flex-col gap-5 md:gap-12 justify-between md:grid md:grid-cols-1 items-center mb-32">            
            <Image 
                src="tijoe-model.gif" 
                alt='Мини-мини тидж'
                width={300}
                height={300}
                priority={true}
                className='block md:hidden mx-auto my-5'/>

            <section className="grid-cols-2 grid md:grid md:grid-cols-2 gap-5 w-full mx-auto">
                {cardData.map((item, index) =>
                  <LinkCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    bg={item.bg}
                    icon={item.icon.component}
                    iconColor={item.icon.color}
                    link={item.link.path}
                    isLink={item.link.isLink}
                    className={classNames({'col-span-2': item.title == 'discord'})}/>
                )}
            </section>

            <p className="hidden md:flex text-sm flex-row gap-5 items-center justify-center text-center mx-auto">
                <RiArrowDownSLine className="animate-bounce text-xs md:text-base mt-2"/>
                Скролль вниз, чтобы увидеть больше
                <RiArrowDownSLine className="animate-bounce text-xs md:text-base mt-2"/>
            </p>
        </article>

        <article className="flex flex-col items-center gap-24 md:gap-48 my-10 px-3 mx-auto" id="discord">
            <DiscordPreview/>
            <GameReviewsPreview/>
            <MusicPreview/>
            <Image 
                src="tijoe-model.gif" 
                alt='Мини-мини тидж'
                width={300}
                height={300}
                priority={true}
                className='md:block hidden'/>
        </article>
      </>
    )
}

Home.getLayout = function getLayout(page: ReactElement) {
    return (
        <RootLayout>{page}</RootLayout>
    )
}

export default Home;

export const getStaticProps: GetStaticProps<Props> = async () => {
    return {
      props: {
        siteUrl: process.env['SITE_URL'] as string,
      },
    };
};